import { axios } from '@/utils/request'

//账号密码登录
export function login(data) {
    return axios({
        url: '/sys/login',
        method: 'post',
        data
    })

}

//手机号登录
export function phoneLogin(parameter) {
    return axios({
        url: '/sys/phoneLogin',
        method: 'post',
        data: parameter
    })
}

//退出登录
export function logout(logoutToken) {
    return axios({
        url: '/sys/logout',
        method: 'post',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'X-Access-Token': logoutToken
        }
    })
}

//获取图片验证码
// export function getCode(key) {
//     return requset({
//         url: '/sys/randomImage/' + key,
//         method: 'get',
//         // params: parameter
//     })
// }