import Vue from 'vue'
import { login, phoneLogin, logout } from "@/api/login"
import { ACCESS_TOKEN, USER_NAME, USER_INFO, USER_AUTH, SYS_BUTTON_AUTH, UI_CACHE_DB_DICT_DATA, TENANT_ID } from "@/store/mutation-types"
import { welcome } from "@/utils/utils"
import { getToken, setToken, removeToken } from '@/utils/auth'
import Cookies from 'js-cookie'

const user = {
    state: {
        token: getToken(),
        username: Cookies.get('username'),
        welcome: '',
        avatar: '',
        info: Cookies.get('info')
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
            console.log(token);
        },
        SET_NAME: (state, { username, realname, welcome }) => {
            state.username = username
            state.realname = realname
            state.welcome = welcome
            Cookies.set('username', username)
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_INFO: (state, info) => {
            state.info = info
            Cookies.set('info', info)
        }
    },

    actions: {
        // 登录
        Login({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                login(userInfo).then(response => {
                    if (response.code == '200') {
                        const result = response.result
                        const info = result.userInfo
                        console.log(userInfo);
                        // Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
                        // Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000)
                        // Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
                        // Vue.ls.set(UI_CACHE_DB_DICT_DATA, result.sysAllDictItems, 7 * 24 * 60 * 60 * 1000)
                        commit('SET_TOKEN', result.token)
                        commit('SET_INFO', info)
                        commit('SET_NAME', { username: userInfo.username, realname: userInfo.realname, welcome: welcome() })
                        commit('SET_AVATAR', userInfo.avatar)

                        setToken(result.token)
                            // setToken(result.username)
                        resolve(response)
                    } else {
                        reject(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        //手机号登录
        PhoneLogin({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                phoneLogin(userInfo).then(response => {
                    if (response.code == '200') {
                        const result = response.result
                        const userInfo = result.userInfo
                        commit('SET_TOKEN', result.token)
                        commit('SET_INFO', userInfo)
                        commit('SET_NAME', { username: userInfo.username, realname: userInfo.realname, welcome: welcome() })
                        commit('SET_AVATAR', userInfo.avatar)
                        resolve(response)
                    } else {
                        reject(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        Logout({ commit, state }) {
            return new Promise((resolve) => {
                let logoutToken = state.token;
                commit('SET_TOKEN', '')
                removeToken()
                logout(logoutToken).then(() => {

                    // localStorage.clear();
                    // // Cookies.remove('userInfo')
                    // sessionStorage.removeItem('token')

                    resolve()
                }).catch(() => {
                    resolve()
                })
            })
        },

        // remove token
        resetToken({ commit }) {
            return new Promise(resolve => {
                commit('SET_TOKEN', '')
                commit('SET_NAME', '')
                removeToken()
                resolve()
            })
        },
    }
}

export default user