import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Index',
    component: () =>
      import('@/views/home/index.vue'),
    meta: {
      title: '首页'
    },
  },
  {
    path: '/news',
    name: 'News',
    component: () =>
      import('@/views/news/index.vue'),
    meta: {
      title: '新闻公告'
    }
  },
  {
    path: '/apply',
    name: 'Apply',
    component: () =>
      import('@/views/apply/index.vue'),
    meta: {
      title: '高新申报'
    }
  },
  {
    path: '/innovation',
    name: 'Innovation',
    component: () =>
      import('@/views/innovation/index.vue'),
    meta: {
      title: '制度创新'
    }
  },
  {
    path: '/resource',
    name: 'Resource',
    component: () =>
      import('@/views/resource/index.vue'),
    meta: {
      title: '资源共享'
    }
  },
  {
    path: '/center',
    name: 'Center',
    component: () =>
      import('../../src/views/center/index.vue'),
    meta: {
      title: '我的地盘'
    }
  },
  {
    path: '/cloud',
    name: 'Cloud',
    component: () =>
      import('@/views/enterpriseCloud/index.vue'),
    meta: {
      title: '企业云'
    }
  },
  {
    path: '/cloudDetail',
    name: 'CloudDetail',
    component: () =>
      import('@/views/enterpriseCloud/Detail/index.vue'),
    meta: {
      title: '企业云内页'
    }
  },
  {
    path: '/comHome',
    name: 'ComHome',
    component: () =>
      import('@/views/enterpriseCloud/comHome/index.vue'),
    meta: {
      title: '企业首页'
    }
  },
  {
    path: '/comment',
    name: 'Comment',
    component: () =>
      import('@/views/comment/index.vue'),
    meta: {
      title: '好差评'
    }
  },
  {
    path: '/commentDetail',
    name: 'CommentDetail',
    component: () =>
      import('@/views/comment/mine/index.vue'),
    meta: {
      title: '好差评内页'
    }
  },
  {
    path: '/addComment',
    name: 'AddComment',
    component: () =>
      import('@/views/comment/add/comment.vue'),
    meta: {
      title: '我要评价'
    }
  },
  {
    path: '/addReport',
    name: 'AddReport',
    component: () =>
      import('@/views/comment/add/report.vue'),
    meta: {
      title: '我要举报'
    }
  },
  {
    path: '/commentItem',
    name: 'CommentItem',
    component: () =>
      import('@/views/comment/commentItem/index.vue'),
    meta: {
      title: '评价内容'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import('@/views/user/login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () =>
      import('@/views/user/register.vue'),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/forgetFirst',
    name: 'ForgetFirst',
    component: () =>
      import('@/views/forgetPassWord/stepFirst.vue'),
    meta: {
      title: '忘记密码'
    }
  },
  {
    path: '/forgetSecond',
    name: 'ForgetSecond',
    component: () =>
      import('@/views/forgetPassWord/stepSecond.vue'),
    meta: {
      title: '忘记密码'
    }
  },
  {
    path: '/forgetThird',
    name: 'ForgetThird',
    component: () =>
      import('@/views/forgetPassWord/stepThird.vue'),
    meta: {
      title: '忘记密码'
    }
  },
  {
    path: '/personalManagement',
    name: 'PersonalManagement',
    component: () =>
      import('@/views/center/personalManagement.vue'),
    meta: {
      title: '企业管理'
    }
  },
  {
    path: '/personalClaim',
    name: 'PersonalClaim',
    component: () =>
      import('@/views/center/personalClaim.vue'),
    meta: {
      title: '企业管理-认领记录'
    }
  },
  {
    path: '/personalHomeEdit',
    name: 'PersonalHomeEdit',
    component: () =>
      import('@/views/center/personalHomeEdit.vue'),
    meta: {
      title: '企业管理-主页编辑'
    }
  },
  {
    path: '/personalRecharge',
    name: 'PersonalRecharge',
    component: () =>
      import('@/views/center/personalRecharge.vue'),
    meta: {
      title: '账户管理'
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () =>
      import('@/views/center/account.vue'),
    meta: {
      title: '账户管理记录'
    }
  },
  {
    path: '/declare',
    name: 'Declare',
    component: () =>
      import('@/views/center/declare.vue'),
    meta: {
      title: '自主申报'
    }
  },
  {
    path: '/declareEdit',
    name: 'DeclareEdit',
    component: () =>
      import('@/views/center/declareEdit.vue'),
    meta: {
      title: '自主申报-编辑'
    }
  },
  {
    path: '/declareMine',
    name: 'DeclareMine',
    component: () =>
      import('@/views/center/declareMine.vue'),
    meta: {
      title: '自主申报-我的申报'
    }
  },
  {
    path: '/declareProjectManage',
    name: 'DeclareProjectManage',
    component: () =>
      import('@/views/center/declareProjectManage.vue'),
    meta: {
      title: '自主申报-项目管理'
    }
  },
  {
    path: '/notification',
    name: 'Notification',
    component: () =>
      import('@/views/center/notification.vue'),
    meta: {
      title: '消息通知'
    }
  },
  {
    path: '/resourceCenter',
    name: 'ResourceCenter',
    component: () =>
      import('@/views/center/resource.vue'),
    meta: {
      title: '资源共享'
    }
  },
  {
    path: '/resourceManage',
    name: 'ResourceManager',
    component: () =>
      import('@/views/center/resourceManage.vue'),
    meta: {
      title: '资源共享管理'
    }
  },
  {
    path: '/resourceList',
    name: 'ResourceList',
    component: () =>
      import('@/views/center/resourceList.vue'),
    meta: {
      title: '我发布的资源'
    }
  },
  {
    path: '/report',
    name: 'Report',
    component: () =>
      import('@/views/center/report.vue'),
    meta: {
      title: '评价举报'
    }
  },
  {
    path: '/recharge',
    name: 'Recharge',
    component: () =>
      import('@/views/center/recharge.vue'),
    meta: {
      title: '会员中心'
    }
  },
  {
    path: '/newsDetail',
    name: 'NewsDetail',
    component: () =>
      import('@/views/news/detail.vue'),
    meta: {
      title: '新闻详情'
    }
  },
  {
    path: '/myApply',
    name: 'MyApply',
    component: () =>
      import('@/views/apply/myApply.vue'),
    meta: {
      title: '我的申报'
    }
  },
  {
    path: '/reject',
    name: 'Reject',
    component: () =>
      import('@/views/apply/reject.vue'),
    meta: {
      title: '修改申报'
    }
  },
  {
    path: '/innovationDetail',
    name: 'InnovationDetail',
    component: () =>
      import('@/views/innovation/detail.vue'),
    meta: {
      title: '制度创新详情'
    }
  },
  {
    path: '/resourceDetail',
    name: 'ResourceDetail',
    component: () =>
      import('@/views/resource/detail.vue'),
    meta: {
      title: '资源共享详情'
    }
  },
  {
    path: '/myNotification',
    name: 'MyNotification',
    component: () =>
      import('@/views/center/myNotification.vue'),
    meta: {
      title: '我发布的消息'
    }
  },
  {
    path: '/membership',
    name: 'Membership',
    component: () =>
      import('@/views/center/membership.vue'),
    meta: {
      title: '会议中心开通记录'
    }
  },
  {
    path: '/guide',
    name: 'Guide',
    component: () =>
      import('@/views/apply/guide.vue'),
    meta: {
      title: '申报指南'
    }
  },
  {
    path: '/newsDetail1',
    name: 'NewsDetail1',
    component: () =>
        import ('@/views/news/detail1.vue'),
    meta: {
        title: '新闻详情1'
    }
  },
  {
      path: '/newsDetail2',
      name: 'NewsDetail2',
      component: () =>
          import ('@/views/news/detail2.vue'),
      meta: {
          title: '新闻详情2'
      }
  },
  {
      path: '/newsDetail3',
      name: 'NewsDetail3',
      component: () =>
          import ('@/views/news/detail3.vue'),
      meta: {
          title: '新闻详情3'
      }
  },
  {
      path: '/newsDetail4',
      name: 'NewsDetail4',
      component: () =>
          import ('@/views/news/detail4.vue'),
      meta: {
          title: '新闻详情4'
      }
  },
  {
      path: '/newsDetail5',
      name: 'NewsDetail5',
      component: () =>
          import ('@/views/news/detail5.vue'),
      meta: {
          title: '新闻详情5'
      }
  },
  {
      path: '/newsDetail6',
      name: 'NewsDetail6',
      component: () =>
          import ('@/views/news/detail6.vue'),
      meta: {
          title: '新闻详情6'
      }
  },
  {
      path: '/newsDetail7',
      name: 'NewsDetail7',
      component: () =>
          import ('@/views/news/detail7.vue'),
      meta: {
          title: '新闻详情7'
      }
  },
  {
      path: '/newsDetail8',
      name: 'NewsDetail8',
      component: () =>
          import ('@/views/news/detail8.vue'),
      meta: {
          title: '新闻详情8'
      }
  },
  {
      path: '/newsDetail9',
      name: 'NewsDetail9',
      component: () =>
          import ('@/views/news/detail9.vue'),
      meta: {
          title: '新闻详情9'
      }
  },
  {
      path: '/newsDetail10',
      name: 'NewsDetail10',
      component: () =>
          import ('@/views/news/detail10.vue'),
      meta: {
          title: '新闻详情10'
      }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router