import Vue from 'vue'
import App from './App.vue'
import Storage from 'vue-ls'
import router from './router'
import store from './store'

import {
    ACCESS_TOKEN,
    DEFAULT_COLOR,
    DEFAULT_THEME,
    DEFAULT_LAYOUT_MODE,
    DEFAULT_COLOR_WEAK,
    SIDEBAR_TYPE,
    DEFAULT_FIXED_HEADER,
    DEFAULT_FIXED_HEADER_HIDDEN,
    DEFAULT_FIXED_SIDEMENU,
    DEFAULT_CONTENT_WIDTH_TYPE,
    DEFAULT_MULTI_PAGE
} from "@/store/mutation-types"
import config from '@/defaultSettings'


/*引入element组件*/
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import 'element-ui/lib/theme-chalk/index.css';
// 引入全局css
import '@/styles/index.scss' // global css


import Router from 'vue-router'
Vue.use(Router)

// 解决报错
const originalPush = Router.prototype.push
    // 针对 push 方法
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
Vue.config.productionTip = false;
Vue.use(Storage, config.storageOptions)
Vue.prototype.$store = store; //挂载在 Vue 实例上
// 轮播图
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
Vue.use(VueAwesomeSwiper);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')