<template>
  <div id="app" v-title title="临空经济">
    <router-view />
    <!-- 路由容器，插槽容器，把路由添加进来 -->
  </div>
</template>
<script>
import title from './utils/auTitle';
export default {

}
</script>
<style lang="scss" scoped></style>


