import axios from "axios";
const service = axios.create({
    // baseURL: "请求头地址",
    baseURL: "http://192.168.1.188:8585/eda-service/",
    timeout: 60000 //过期时间
})

// request interceptor
service.interceptors.request.use()
    // response interceptor
service.interceptors.response.use(
    res => {
        // const res = response.data
        return res.data
    }
)

export {
    service as axios
}
// export default service